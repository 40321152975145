<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-left-panel :data-item='dataItem'></project-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Ayarlar</h3>

					<p class='text-lg'>
					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ProjectTabMenu :project-id='projectId'></ProjectTabMenu>
                <div class='col-12'>


                    <div class='field grid mb-5'>
                        <label for='projectLogo' class='col-12 mb-2 md:col-2 md:mb-0'>Proje Logo</label>

                        <div class='col-12 md:col-8'>
                            <InputText id='projectLogo' type='text' v-model='dataItem.projectLogo' />
                        </div>

                        <div class='col-12 md:col-2'>
                            <FileUpload choose-label="Upload" mode="basic" name="upload[]" :url="uploadUrl" :auto="true" @before-upload='uploadStarted' @upload="uploadFinished" class="p-button-danger"/>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='breakfastPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Kahvaltı</label>
                        <label class='col-6 mt-2 md:col-2 md:mb-0 md:mt-0'>
                            Durum
                            <InputSwitch id='isBreakfastVisible' v-model='projectDefaultDataItem.isBreakfastVisible'
                                         class='ml-2' />
                        </label>

                        <label class='col-6 mt-2 md:col-2 md:mb-0 md:mt-0'>
                            Ön Sipariş
                            <InputSwitch id='isBreakfastOrderVisible'
                                         v-model='projectDefaultDataItem.isBreakfastOrderVisible' class='ml-2' />
                        </label>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='lunchPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Öğle Yemeği</label>
                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Durum
                            <InputSwitch id='isLunchVisible' v-model='projectDefaultDataItem.isLunchVisible'
                                         class='ml-2' />
                        </label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Ön Sipariş
                            <InputSwitch id='isLunchOrderVisible' v-model='projectDefaultDataItem.isLunchOrderVisible'
                                         class='ml-2' />
                        </label>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='dinnerQuantity' class='col-12 mb-2 md:col-2 md:mb-0'>Akşam Yemeği</label>
                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Durum
                            <InputSwitch id='isDinnerVisible' v-model='projectDefaultDataItem.isDinnerVisible'
                                         class='ml-2' />
                        </label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Ön Sipariş
                            <InputSwitch id='isDinnerOrderVisible' v-model='projectDefaultDataItem.isDinnerOrderVisible'
                                         class='ml-2' />
                        </label>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='nightMealPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Gece Yemeği</label>
                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Durum
                            <InputSwitch id='isNightMealVisible' v-model='projectDefaultDataItem.isNightMealVisible'
                                         class='ml-2' />
                        </label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Ön Sipariş
                            <InputSwitch id='isNightMealOrderVisible'
                                         v-model='projectDefaultDataItem.isNightMealOrderVisible' class='ml-2' />
                        </label>
                    </div>


                    <div class='field grid mb-5'>
                        <label for='snackPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Kumanya</label>
                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Durum
                            <InputSwitch id='isSnackVisible' v-model='projectDefaultDataItem.isSnackVisible'
                                         class='ml-2' />
                        </label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Ön Sipariş
                            <InputSwitch id='isSnackOrderVisible' v-model='projectDefaultDataItem.isSnackOrderVisible'
                                         class='ml-2' />
                        </label>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='iftarPrice' class='col-12 mb-2 md:col-2 md:mb-0'>İftar</label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Durum
                            <InputSwitch id='isIftarVisible' v-model='projectDefaultDataItem.isIftarVisible'
                                         class='ml-2' />
                        </label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Ön Sipariş
                            <InputSwitch id='isIftarOrderVisible' v-model='projectDefaultDataItem.isIftarOrderVisible'
                                         class='ml-2' />
                        </label>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='sahurPrice' class='col-12 mb-2 md:col-2 md:mb-0'>Sahur</label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Durum
                            <InputSwitch id='isSahurVisible' v-model='projectDefaultDataItem.isSahurVisible' class='ml-2' />
                        </label>

                        <label class='col-6 mb-2 mt-2 md:col-2 md:mb-0'>
                            Ön Sipariş
                            <InputSwitch id='isSahurOrderVisible' v-model='projectDefaultDataItem.isSahurOrderVisible' class='ml-2' />
                        </label>
                    </div>

                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mb-2' @click='save'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>

</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage,
    showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';
import ProjectLeftPanel from './projectLeftPanel';
import ProjectTabMenu from './projectTabMenu';
import ProjectDefaultService from '../../services/projectDefaultService';
import { getProject } from '../common/commonFunctions';
import { checkActiveProjectIdIsValid } from '../common/commonConstantFunctions';




export default {
    components: { ProjectTabMenu, ProjectLeftPanel, AdminToast },
    _projectService: null,
    _projectDefaultService: null,
    created() {
        this._projectService = new ProjectService();
        this._projectDefaultService = new ProjectDefaultService();
        this.projectId = this.$route.params.projectId;
    },
    async mounted() {
        checkActiveProjectIdIsValid(this, this.projectId);
        this.dataItem = await getProject(this.projectId);
        await this.getProjectDefaults(this.projectId);
        this.uploadUrl = process.env.VUE_APP_MAIN_API + "/api/upload/static-image?fileType=Logo&resourceId=" + this.projectId + "&resourceType=Project";
    },
    data() {
        return {
            uploadUrl:"",
            returnField: '',
            projectId: 0,
            projectDefaultDataItem:{},
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                personName: '',
                personPhone: '',
                personEmail: '',
                projectLogo: 'ProjeLogo/LogoDefault.png',
            },

            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {

            if (this.validateForm()) {
                this.dataItem.projectLogo = this.dataItem.projectLogo.replace('./', '');
                const updateResponse = await this._projectService.updateProjectLogo(this.projectId, this.dataItem);
                if (updateResponse.isSuccess) {
                    await this.updateProjectVisibilities();
                } else {
                    showErrorMessage(this, 'Proje Güncellemede Sorun İle Karşılaşıldı');
                }
            }
        },
        validateForm() {
            if (this.dataItem.projectLogo == null || this.dataItem.projectLogo == '') {
                showValidationMessage(this, 'Logo Alanı Boş Bırakılamaz');
                return false;
            }
            return true;
        },

        async updateProjectVisibilities() {
            const updateResponse = await this._projectDefaultService.updateProjectVisibleSettings(this.projectId, this.projectDefaultDataItem);
            if (updateResponse.isSuccess) {
                showSuccessMessage(this, 'Proje Güncelleme Başarılı');
            } else {
                showErrorMessage(this, 'Fiyat Güncellemede Sorun İle Karşılaşıldı');
            }
        },

        onFileSelected(event, fieldName) {
            this.$data['dataItem'][fieldName] = event.data.path;
            this.$refs.op.hide();
        },
        openFileBrowser(fieldName) {
            this.returnField = fieldName;
            this.$refs.op.toggle(event);
        },

        async getProjectDefaults(projectId) {
            let projectDefaultResponse = await this._projectDefaultService.getProjectDefaultsById(projectId);
            if (projectDefaultResponse.isSuccess) {
                this.projectDefaultDataItem.projectId = projectDefaultResponse.data.projectId;
                this.projectDefaultDataItem.isBreakfastVisible = projectDefaultResponse.data.isBreakfastVisible;
                this.projectDefaultDataItem.isLunchVisible = projectDefaultResponse.data.isLunchVisible;
                this.projectDefaultDataItem.isDinnerVisible = projectDefaultResponse.data.isDinnerVisible;
                this.projectDefaultDataItem.isSnackVisible = projectDefaultResponse.data.isSnackVisible;
                this.projectDefaultDataItem.isIftarVisible = projectDefaultResponse.data.isIftarVisible;
                this.projectDefaultDataItem.isSahurVisible = projectDefaultResponse.data.isSahurVisible;
                this.projectDefaultDataItem.isNightMealVisible = projectDefaultResponse.data.isNightMealVisible;
                this.projectDefaultDataItem.isBreakfastOrderVisible = projectDefaultResponse.data.isBreakfastOrderVisible;
                this.projectDefaultDataItem.isLunchOrderVisible = projectDefaultResponse.data.isLunchOrderVisible;
                this.projectDefaultDataItem.isDinnerOrderVisible = projectDefaultResponse.data.isDinnerOrderVisible;
                this.projectDefaultDataItem.isIftarOrderVisible = projectDefaultResponse.data.isIftarOrderVisible;
                this.projectDefaultDataItem.isSnackOrderVisible = projectDefaultResponse.data.isSnackOrderVisible;
                this.projectDefaultDataItem.isSahurOrderVisible = projectDefaultResponse.data.isSahurOrderVisible;
                this.projectDefaultDataItem.isNightMealOrderVisible = projectDefaultResponse.data.isNightMealOrderVisible;
            }
        },

        async uploadFinished(event) {
            this.dataItem.projectLogo = event.xhr.responseText
            this.loadingIndicator = false;
        },
        uploadStarted() {
            this.loadingIndicator = true;
        },

    },
};

</script>


<style scoped>

</style>
